import React from 'react';
import PropTypes from 'prop-types';
import {
	Checkbox,
	FormControl,
	FormGroup,
	FormLabel,
	Radio,
	TextField,
} from '@material-ui/core';
import { FormControlLabel, RadioGroup } from './styles';
import PhoneInput from './PhoneInput';

const Form = props => {
	const {
		name,
		setName,
		home,
		auto,
		business,
		other,
		handleRadioChange,
		contactValue,
		setContactValue,
		email,
		setEmail,
		setPhone,
		phone,
		handleSubmit,
	} = props;
	return (
		<form
			method='post'
			name='quoteForm'
			netlify-honeypot='bot-field'
			data-netlify='true'
			onSubmit={handleSubmit}>
			<input type='hidden' name='form-name' value='contact' />
			<input type='hidden' name='bot-field' />
			<FormControl component='fieldset' style={{ margin: '0.75rem' }}>
				<TextField
					margin='dense'
					id='name'
					label="What's Your Name?"
					type='name'
					name='name'
					value={name}
					onChange={e => setName(e.target.value)}
				/>
				<div style={{ display: 'flex' }}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={home}
									onChange={handleRadioChange('home')}
									value='home'
									name='home_insurance'
								/>
							}
							label='Home Insurance'
						/>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={auto}
									onChange={handleRadioChange('auto')}
									value='auto'
									name='auto_insurance'
								/>
							}
							label='Auto Insurance'
						/>
					</FormGroup>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={business}
									onChange={handleRadioChange('business')}
									value='business'
									name='business_insurance'
								/>
							}
							label='Business Insurance'
						/>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={other}
									onChange={handleRadioChange('other')}
									value='other'
									name='other_insurance'
								/>
							}
							label='Other Insurance'
						/>
					</FormGroup>
				</div>
			</FormControl>
			<FormControl component='fieldset'>
				<FormLabel component='legend'>
					How would you like to be contacted?
				</FormLabel>
				<RadioGroup
					aria-label='contact_method'
					name='contact_method'
					value={contactValue}
					onChange={e => setContactValue(e.target.value)}
					row>
					<FormControlLabel
						value='phone'
						control={<Radio color='primary' />}
						label='Phone'
						labelPlacement='bottom'
						name='phone_contact'
					/>
					<FormControlLabel
						value='email'
						control={<Radio color='primary' />}
						label='Email'
						labelPlacement='bottom'
						name='email_contact'
					/>
				</RadioGroup>
			</FormControl>
			{contactValue === 'email' ? (
				<TextField
					margin='dense'
					id='email'
					label='Email Address'
					type='email'
					name='email_address'
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
			) : (
				<PhoneInput
					margin='dense'
					id='phone'
					label='Phone Number'
					name='phone_number'
					setValue={setPhone}
					value={phone}
				/>
			)}
		</form>
	);
};

Form.propTypes = {
	name: PropTypes.string,
	setName: PropTypes.func,
	home: PropTypes.bool,
	auto: PropTypes.bool,
	business: PropTypes.bool,
	other: PropTypes.bool,
	handleRadioChange: PropTypes.func,
	contactValue: PropTypes.string,
	setContactValue: PropTypes.func,
	email: PropTypes.string,
	setEmail: PropTypes.func,
	setPhone: PropTypes.func,
	phone: PropTypes.string,
	handleSubmit: PropTypes.func,
};

export default Form;
