const slideRightTop = {
	closed: ({ burgerColor }) => ({
		background: burgerColor,
		rotate: '0deg',
	}),
	open: ({ xColor }) => ({
		background: xColor,
		rotate: '45deg',
	}),
};
const slideRightMiddle = {
	closed: ({ burgerColor }) => ({
		background: burgerColor,
		opacity: 1,
		pointerEvents: 'auto',
		x: 0,
	}),
	open: ({ xColor }) => ({
		background: xColor,
		opacity: 0,
		pointerEvents: 'none',
		x: 90,
	}),
};
const slideRightBottom = {
	closed: ({ burgerColor }) => ({
		background: burgerColor,
		rotate: '0deg',
	}),
	open: ({ xColor }) => ({
		background: xColor,
		rotate: '-45deg',
	}),
};
export const SLIDE_RIGHT = [slideRightTop, slideRightMiddle, slideRightBottom];
