import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTransform, useViewportScroll } from 'framer-motion';
import { AppBar, Button, Hidden, Toolbar } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { ContactSupport } from '@material-ui/icons';
import logo from 'assets/img/ARCLG.png';
import { headerStyle } from './Header.css';
import HeaderLinks from './HeaderLinks';
import Drawer from './Drawer';
import { Hamburger } from './Hamburger';
import ContactModal from './Contact/ContactModal';

// eslint-disable-next-line react/prop-types
export const QuoteButton = ({ className, onClick }) => (
	<Button
		style={{ marginRight: '1rem' }}
		color='inherit'
		aria-label='open modal'
		className={className}
		onClick={onClick}>
		Get a Quote
		<ContactSupport
			style={{
				margin: '0 0 0 0.4rem',
				width: '20px',
				height: '20px',
				marginRight: '3px',
			}}
		/>
	</Button>
);
const Header = ({ absolute, classes, fixed, scrollChange }) => {
	const theme = useTheme();
	const { scrollYProgress } = useViewportScroll();

	const [white, setWhite] = useState(false);
	const [transparent, setTransparent] = useState(true);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const yRange = useTransform(scrollYProgress, [0, 1]);
	useEffect(() => {
		if (scrollChange) {
			return yRange.onChange(v => {
				if (v < 0.25) {
					setTransparent(true);
					setWhite(false);
				} else {
					setTransparent(false);
					setWhite(true);
				}
			});
		}
	}, [yRange]);
	useEffect(() => {
		if (mobileOpen) {
			setTransparent(false);
			setWhite(true);
		} else {
			if (scrollChange) {
				if (scrollYProgress.current < 0.25) {
					setTransparent(true);
					setWhite(false);
				}
			}
		}
	}, [mobileOpen, scrollChange]);
	const appBarClasses = classNames({
		[classes.appBar]: true,
		[classes.absolute]: absolute,
		[classes.fixed]: fixed,
		[classes.transparent]: transparent,
		[classes.white]: white,
	});
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const handleModalToggle = () => {
		setModalOpen(!modalOpen);
	};

	return (
		<>
			<ContactModal open={modalOpen} handleClose={handleModalToggle} />
			<AppBar className={appBarClasses}>
				<Toolbar className={classes.container}>
					<Link
						style={{
							backdropFilter: 'blur(4px)',
							borderRadius: '40px',
							width: '176px',
						}}
						to='/'>
						<img
							style={{
								maxHeight: '80px',
								width: '176px',
								viewBox: '0 0 256px 120px',
							}}
							src={logo}
							alt='ARC Insurance Richmond Virginia'
						/>
					</Link>
					<Hidden mdDown implementation='css'>
						<HeaderLinks onClick={handleModalToggle} />
					</Hidden>
					<Hidden lgUp>
						<div className={classes.flexRight}>
							<Hidden lgUp smDown implementation='css'>
								<QuoteButton onClick={handleModalToggle} />
							</Hidden>
							<Hamburger
								burgerColor={theme.palette.grey[600]}
								xColor={theme.palette.grey[600]}
								toggle={handleDrawerToggle}
								open={mobileOpen}
							/>
						</div>
					</Hidden>
				</Toolbar>
				<Hidden mdUp implementation='css'>
					<Drawer
						classes={classes}
						open={mobileOpen}
						toggle={handleDrawerToggle}
						theme={theme}
						handleModalToggle={handleModalToggle}
					/>
				</Hidden>
			</AppBar>
		</>
	);
};

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	rightLinks: PropTypes.node,
	leftLinks: PropTypes.node,
	brand: PropTypes.func,
	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	scrollChange: PropTypes.bool,
	// the title prop is the siteTitle passed from layout
	title: PropTypes.string.isRequired,
};

Header.defaultProps = {
	scrollChange: true,
};

export default withStyles(headerStyle)(Header);
