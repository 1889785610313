import React from 'react';
import styled from 'styled-components';
import RootDrawer from '@material-ui/core/Drawer';
import DrawerLinks from './DrawerLinks';

const Drawer = styled(
	({ className, classes, handleModalToggle, open, toggle }) => (
		<RootDrawer
			variant='temporary'
			anchor={'right'}
			open={open}
			classes={{
				paper: `${className} paper`,
				root: `${className} root`,
			}}
			onClose={toggle}>
			<div className={`${classes.appResponsive} nav-links`}>
				{<DrawerLinks onClick={handleModalToggle} />}
			</div>
		</RootDrawer>
	)
)`
	&&.root {
		z-index: 10 !important;
	}
	&&.paper {
		.nav-links {
			padding-top: 86px;
			li:last-of-type {
				&::after {
					display: none;
				}
			}
		}
		${props => {
			const { theme } = props;
			const {
				breakpoints: { values },
			} = theme;
			const lgBreak = `max-width: ${values['lg']}px`;
			const mdBreak = `max-width: ${values['md']}px`;
			const smBreak = `max-width: ${values['sm']}px`;
			return `
      @media (${lgBreak}) {
        width: 29%;
      }
      @media (${mdBreak}) {
        width: 30%;
      }
      @media (${smBreak}) {
        width: 70%;
        }
      `;
		}}
	}
`;
export default Drawer;
