import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import { Header } from '../Header/index';
const Layout = ({ data, children, ...rest }) => {
	if (!data) return null;
	return (
		<>
			<Head {...rest} />
			<Header fixed title={data.site.siteMetadata.siteTitle} />
			{children}
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
	<StaticQuery
		query={graphql`
			query LayoutQuery {
				site {
					siteMetadata {
						siteTitle
						siteDescription
						backgroundColor
						siteTitleShort
						siteUrl
						social {
							fbAppId
							twitter
						}
						themeColor
					}
				}
			}
		`}
		render={data => <Layout data={data} {...props} />}
	/>
);

LayoutWithQuery.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
