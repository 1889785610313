import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CoreButton from '@material-ui/core/Button';

// core components

import { buttonStyle } from './Button.css';

const Button = React.forwardRef(function Button(props, ref) {
	const {
		classes,
		color,
		round,
		children,
		fullWidth,
		disabled,
		simple,
		size,
		block,
		link,
		justIcon,
		className,
		...rest
	} = props;
	const btnClasses = classNames({
		[classes.button]: true,
		[classes[size]]: size,
		[classes[color]]: color,
		[classes.round]: round,
		[classes.fullWidth]: fullWidth,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[className]: className,
	});
	return (
		<CoreButton ref={ref} {...rest} className={btnClasses}>
			{children}
		</CoreButton>
	);
});

// const RegularButton = ({
//   classes,
//   color,
//   round,
//   children,
//   fullWidth,
//   disabled,
//   simple,
//   size,
//   block,
//   link,
//   justIcon,
//   className,
//   ...rest
// }) => {
//   const btnClasses = classNames({
//     [classes.button]: true,
//     [classes[size]]: size,
//     [classes[color]]: color,
//     [classes.round]: round,
//     [classes.fullWidth]: fullWidth,
//     [classes.disabled]: disabled,
//     [classes.simple]: simple,
//     [classes.block]: block,
//     [classes.link]: link,
//     [classes.justIcon]: justIcon,
//     [className]: className,
//   });
//   return (
//     <CoreButton {...rest} className={btnClasses}>
//       {children}
//     </CoreButton>
//   );
// };

Button.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'primary',
		'info',
		'success',
		'warning',
		'danger',
		'rose',
		'white',
		'facebook',
		'twitter',
		'google',
		'github',
		'transparent',
	]),
	size: PropTypes.oneOf(['sm', 'lg']),
	simple: PropTypes.bool,
	round: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	link: PropTypes.bool,
	justIcon: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
};

export default withStyles(buttonStyle)(Button);
