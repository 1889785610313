import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const PhoneInput = props => {
	const { value, setValue } = props;
	const handleChange = event => {
		const el = event.target;
		let selectionStart = el.selectionStart;

		const newInput = event.target.value.replace(/\D/g, '');

		const oldValueArr = value.split('-');
		const newValueArr = [
			newInput.slice(0, 3),
			newInput.slice(3, 6),
			newInput.slice(6, 10),
		].filter(x => x);

		const newValue = newValueArr.join('-');
		setValue(newValue);
		selectionStart += Math.max(newValueArr.length - oldValueArr.length, 0);
		el.selectionStart = el.selectionEnd = selectionStart;
	};
	return (
		<TextField
			label='Phone'
			type='tel'
			name='phone'
			value={value}
			onChange={handleChange}
			{...props}
		/>
	);
};

PhoneInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
};

export default PhoneInput;
