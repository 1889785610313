import React from 'react';
import PropTypes from 'prop-types';

import * as S from './Header.css';
import { SLIDE_RIGHT } from './Hamburger.variants';

export const Hamburger = ({
	burgerColor = '#ccc',
	open,
	toggle,
	xColor = '#000',
}) => (
	<S.Hamburger onClick={toggle}>
		<S.Line
			animate={open ? 'open' : 'closed'}
			custom={{
				burgerColor,
				xColor,
			}}
			initial='closed'
			variants={SLIDE_RIGHT[0]}
		/>
		<S.Line
			animate={open ? 'open' : 'closed'}
			custom={{
				burgerColor,
				xColor,
			}}
			initial='closed'
			variants={SLIDE_RIGHT[1]}
		/>
		<S.Line
			animate={open ? 'open' : 'closed'}
			custom={{
				burgerColor,
				xColor,
			}}
			initial='closed'
			variants={SLIDE_RIGHT[2]}
		/>
	</S.Hamburger>
);

Hamburger.propTypes = {
	burgerColor: PropTypes.string,
	open: PropTypes.bool,
	toggle: PropTypes.func,
	xColor: PropTypes.string,
};
