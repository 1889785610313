import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContentText,
	Slide,
	Snackbar,
	useTheme,
	useMediaQuery,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { DialogContent } from './styles';
import Form from './ContactForm';
import { useMemo } from 'react';

const emailRegExp = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/;

const generateWarningMessage = validation => {
	switch (true) {
		case !validation.nameValid:
			return 'Name must be at least 4 characters.';
		case !validation.insuranceTypesValid:
			return 'Please select at least one type of insurance you want to inquire about.';
		case !validation.phoneValid && validation.contact === 'phone':
			return 'Please include a 10 digit phone number.';
		case !validation.emailValid && validation.contact === 'email':
			return 'Please make sure to use a valid email';
		default:
			return 'Please include all needed info.';
	}
};

function Transition(props) {
	return <Slide {...props} direction='up' />;
}

const useContactState = handleClose => {
	const [snackbarOpen, setOpenSnackbar] = useState(false);
	const [snackbarMsg, setSnackbarMsg] = useState('I like snacks!');
	const [snackbarSeverity, setSnackbarSeverity] = useState('success');
	const [insuranceTypes, setInsuranceTypes] = useState({
		home: false,
		auto: false,
		business: false,
		other: false,
	});
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [contactValue, setContactValue] = useState('phone');
	const insuranceTypeMsg = useMemo(() => {
		const filteredInsuranceTypes = Object.keys(insuranceTypes).filter(
			s => insuranceTypes[s] && s
		);
		if (filteredInsuranceTypes.length > 1) {
			const last = filteredInsuranceTypes.pop();
			return `${filteredInsuranceTypes.join(', ')} & ${last}`;
		}
		return filteredInsuranceTypes.join(', ');
	}, [insuranceTypes]);
	const message = useMemo(
		() =>
			`<div>Hey Ryan!</div><div>${name} has requested a quote about ${insuranceTypeMsg} insurance. Can you please reach out via ${contactValue} at ${
				contactValue === 'phone' ? phone : email
			} at your earliest convenience?</div>`,
		[name, contactValue, phone, email]
	);
	const { home, auto, business, other } = insuranceTypes;

	const handleRadioChange = name => event => {
		setInsuranceTypes({ ...insuranceTypes, [name]: event.target.checked });
	};

	const handleValidate = () => {
		const validation = {
			valid: false,
			nameValid: !!name && name.length > 4,
			contact: contactValue,
			phoneValid: contactValue === 'phone' && phone.length === 12,
			emailValid: contactValue === 'email' && emailRegExp.test(email),
			insuranceTypesValid: !!Object.keys(insuranceTypes).filter(
				s => insuranceTypes[s] && s
			).length,
		};
		if (
			validation.nameValid &&
			validation.insuranceTypesValid &&
			(validation.phoneValid || validation.emailValid)
		) {
			validation.valid = true;
		}
		return validation;
	};

	const handleSubmit = async e => {
		e.preventDefault();
		const valid = handleValidate();
		if (!valid.valid) {
			const message = generateWarningMessage(valid);
			setSnackbarSeverity('warning');
			setSnackbarMsg(message);
			return setOpenSnackbar(true);
		}
		try {
			const body = JSON.stringify(message);
			const response = await fetch('/.netlify/functions/sendmail', {
				method: 'POST',
				body,
			});
			// const resBody = await response.body.getReader();
			setSnackbarSeverity('success');

			if (!response.ok) {
				//not 200 response
				setSnackbarSeverity('warning');
			}
			//all OK
			setSnackbarMsg('Message sent. We will respond to you shortly.');
			setOpenSnackbar(true);
			handleClose();
		} catch (e) {
			//error
			setSnackbarSeverity('error');
			setSnackbarMsg(e.body || 'Something went wrong, try again.');
			setOpenSnackbar(true);
		}
	};
	const formProps = useMemo(() => ({
		name,
		setName,
		home,
		auto,
		business,
		other,
		handleRadioChange,
		contactValue,
		setContactValue,
		email,
		setEmail,
		setPhone,
		phone,
		handleSubmit,
	}));
	return {
		formProps,
		snackbarOpen,
		setOpenSnackbar,
		snackbarSeverity,
		snackbarMsg,
	};
};

const ContactModal = ({ open, handleClose }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {
		snackbarOpen,
		setOpenSnackbar,
		snackbarSeverity,
		snackbarMsg,
		formProps,
	} = useContactState(handleClose);

	return (
		<>
			<Dialog
				open={open}
				fullScreen={fullScreen}
				onClose={handleClose}
				maxWidth='lg'
				aria-labelledby='form-dialog-title'>
				<DialogTitle id='form-dialog-title'>
					Get a Quote Today
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Let us know what kind of quote you are looking for and
						we&apos;ll get back to you as soon as possible
					</DialogContentText>
					<Form {...formProps} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button
						type='submit'
						onClick={formProps.handleSubmit}
						color='primary'>
						Get a Quote
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				autoHideDuration={5000}
				open={snackbarOpen}
				onClose={() => setOpenSnackbar(false)}
				TransitionComponent={Transition}>
				<MuiAlert
					elevation={6}
					variant='filled'
					severity={snackbarSeverity}>
					{snackbarMsg}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

ContactModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};

export default ContactModal;
