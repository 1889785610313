import React from 'react';
import styled from 'styled-components';
import {
	DialogContent as DialogContentBase,
	FormControlLabel as FormControlLabelBase,
	RadioGroup as RadioGroupBase,
} from '@material-ui/core';

export const FormControlLabel = styled(props => {
	return <FormControlLabelBase {...props} />;
})`
	color: #000;
`;
export const DialogContent = styled(props => {
	return <DialogContentBase {...props} />;
})`
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const RadioGroup = styled(props => {
	return <RadioGroupBase {...props} />;
})`
	justify-content: space-between;
`;
