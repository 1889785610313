//eslint-disable//
import React from 'react';
import PropTypes from 'prop-types';
// react components for routing our app without refresh
import { Link } from 'gatsby';

// @material-ui/core components
import { useTheme, withStyles } from '@material-ui/core/styles';
import Apps from '@material-ui/icons/Apps';
import { List, ListItem } from '@material-ui/core';

// @material-ui/icons
import {
	Business,
	DirectionsCar,
	Home,
	MailOutline,
	More,
	Phone,
} from '@material-ui/icons';

import Dropdown from 'components/Dropdown/Dropdown';
import { useGlobalContext } from 'store/createContext';
import { QuoteButton } from './Header';

import { headerLinksStyle } from './Header.css';

const iconStyle = { margin: '0 0 0 0.4rem' };

const DropdownLinks = classes => [
	<Link
		key='home'
		to='/services/home-insurance'
		color='transparent'
		className={classes.dropdownLink}>
		Home Insurance <Home style={iconStyle} className={classes.icons} />
	</Link>,
	<Link
		key='auto'
		to='/services/auto-insurance'
		color='transparent'
		className={classes.dropdownLink}>
		Auto Insurance{' '}
		<DirectionsCar style={iconStyle} className={classes.icons} />
	</Link>,
	<Link
		key='business'
		to='/services/business-insurance'
		color='transparent'
		className={classes.dropdownLink}>
		Business Insurance{' '}
		<Business style={iconStyle} className={classes.icons} />
	</Link>,
	<Link
		key='other'
		to='/services/other-insurance'
		color='transparent'
		className={classes.dropdownLink}>
		Other Insurance{' '}
		<More
			style={{ ...iconStyle, transform: 'rotate(180deg)' }}
			className={classes.icons}
		/>
	</Link>,
];

const WithQuoteButton = ({ classes, onClick, dimensions, values }) =>
	dimensions.width > values.lg && (
		<ListItem className={classes.listItem}>
			<QuoteButton onClick={onClick} className={classes.navLink} />
		</ListItem>
	);

const HeaderLinks = ({ classes, onClick }) => {
	const {
		breakpoints: { values },
	} = useTheme();
	const { dimensions } = useGlobalContext();
	return (
		<List className={classes.list}>
			<ListItem className={classes.listItem}>
				<Dropdown
					noLiPadding
					buttonText='Insurance Services'
					buttonProps={{
						className: classes.navLink,
						color: 'transparent',
					}}
					buttonIcon={Apps}
					dropdownList={DropdownLinks(classes)}
				/>
			</ListItem>
			<ListItem className={classes.listItem}>
				<Link
					to='/about'
					color='transparent'
					className={classes.navLink}>
					About ARC Insurance
				</Link>
			</ListItem>
			<WithQuoteButton
				classes={classes}
				onClick={onClick}
				dimensions={dimensions}
				values={values}
			/>
			<ListItem className={classes.listItem}>
				<a
					className={classes.navLink}
					href='mailto:arcinsuranceofva@gmail.com?subject=Looking for a Quote'>
					Email
					<MailOutline style={iconStyle} className={classes.icons} />
				</a>
			</ListItem>
			<ListItem className={classes.listItem}>
				<a className={classes.navLink} href='tel:8043345252'>
					Call
					<Phone style={iconStyle} className={classes.icons} />
				</a>
			</ListItem>
		</List>
	);
};

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func,
};

WithQuoteButton.propTypes = {
	classes: PropTypes.object.isRequired,
	dimensions: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	values: PropTypes.object.isRequired,
};

export default withStyles(headerLinksStyle)(HeaderLinks);
